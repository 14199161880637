export default {
    'userPoolId': 'eu-west-2_agbmRtPwN',
    'aws_cognito_region': 'eu-west-2',
    'userPoolWebClientId': '5j4uheq1ug1ofp8i6ud9a2dav8',
    'identityPoolId': 'eu-west-2:a3f5964a-a761-4a82-8d1e-c3d8b794d5c8',
    'region': 'eu-west-2',
    'Analytics': {
        'AWSPinpoint': {
            'appId': '95869267e91f46f8938df340354866da',
            'region': 'eu-west-1'
        },
    },
};